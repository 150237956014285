<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Which of the following statements about chemical equilibria are true?
        <b>Select all that apply</b>.
      </p>

      <v-checkbox
        v-for="option in options"
        :key="option.value"
        v-model="inputs.input1"
        :value="option.value"
        :dense="true"
        hide-details="true"
        :ripple="false"
        class="pl-8 mb-0"
        :label="option.condition"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'Question100',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {
          condition: 'The concentrations of the reactants and products are equal',
          value: '1',
        },
        {
          condition: 'The concentrations of reactants and products remain constant',
          value: '2',
        },
        {
          condition: 'The reactants are still being converted to products (and vice versa)',
          value: '3',
        },
        {
          condition: 'The rates of the forward and reverse reactions are equal',
          value: '4',
        },
        {
          condition: 'The rate constants for the forward and reverse reactions are equal',
          value: '5',
        },
      ],
    };
  },
};
</script>
